<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="timesheets_lines"
      class="elevation-0 myTable"
      disable-pagination
      hide-default-footer
      @click:row="handleTableClick"
      :options.sync="options"
      :server-items-length="total"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:no-data>
        Aucune ligne
      </template>
      <template v-slot:top>
        <v-toolbar flat extended extension-height="100">
          <v-toolbar-title id="toolbar-title">
            Timesheets centralisés</v-toolbar-title
          >
          <AutoComplete
            id="year"
            class="year-input"
            v-model="editedItem.year"
            :items="years"
            item-text="name"
            return-object
            style="margin-left:20px; width:155px"
            multiple
            chips
            deletable-chips
          />
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="418px">
            <template v-slot:activator="{}">
              <Button
                action_name="Exporter"
                class="mb-2"
                color="primary"
                text
                @clicked="exportData"
                :loading="loadingExport"
              >
                <template v-slot:button-content>
                  <v-img
                    :src="require('@/assets/export.svg')"
                    alt="humberger"
                  />
                </template>
              </Button>
              <span class="mr-5 total-hours">
                <em class="total">Estimé : </em>
                {{ estimated | moment_hours }} heures
              </span>
              <span class="mr-5 total-hours">
                <em class="total">Passé : </em>
                {{ hours | moment_hours }} heures
              </span>
            </template>
          </v-dialog>
          <v-dialog v-model="filterDialog" max-width="836px">
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">Filtre</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="agent"
                        v-model="editedItem.agent"
                        :items="agents"
                        label="Agent"
                        item-text="name"
                        return-object
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="client"
                        v-model="editedItem.client"
                        :items="clients"
                        label="Client"
                        item-text="name"
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="department"
                        v-model="editedItem.operation"
                        :items="operations"
                        label="Type de tâche"
                        item-text="name"
                        multiple
                        deletable-chips
                        small-chips
                        return-object
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="dossier"
                        v-model="editedItem.dossier"
                        :items="dossiers"
                        label="Dossier"
                        item-text="name"
                        multiple
                        deletable-chips
                        small-chips
                        return-object
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <AutoComplete
                        id="dossier"
                        v-model="editedItem.department"
                        :items="departments"
                        label="Département"
                        item-text="name"
                        multiple
                        deletable-chips
                        small-chips
                        return-object
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <text-input
                        id="title"
                        label="Recherche(Code et détails)"
                        v-model="editedItem.title"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      sm="6"
                      class="ma-0 pb-0"
                      v-if="!disabledExactDate"
                    >
                      <label>Date</label>
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0 d-inline-block select-multiple-date"
                        v-model="checkDate"
                        @change="handleMultipleDate(true)"
                      ></v-checkbox>
                      <custom-date-picker v-model="editedItem.exact_date" />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      sm="6"
                      class="ma-0 pb-0"
                      v-if="showMultipleDates"
                    >
                      <label>Date début</label>
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0 d-inline-block select-multiple-date"
                        v-model="checkDate"
                        @change="handleMultipleDate(false)"
                      ></v-checkbox>
                      <custom-date-picker v-model="editedItem.start_date" />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      sm="6"
                      class="ma-0 pb-0"
                      v-if="showMultipleDates"
                    >
                      <label>Date fin</label>
                      <custom-date-picker v-model="editedItem.end_date" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6">
                <Button
                  action_name="Filtrer"
                  color="#86CC26"
                  @clicked="save"
                  style="width: 220px"
                  class="ml-5 mr-5"
                  :loading="loading"
                />
                <span style="color:#B2BBAB" @click="handleReset"
                  >Réinitialiser</span
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template v-slot:extension>
            <div class="filter-block">
              <div>
                <template v-for="(agent, index) in editedItem.agent">
                  <v-chip
                    class="ma-1"
                    :key="agent.name"
                    close
                    @click:close="handleChipDelete(index, 'agent')"
                    >Agent:{{ agent.name }}</v-chip
                  >
                </template>
                <template v-for="(dossier, index) in editedItem.dossier">
                  <v-chip
                    class="ma-1"
                    :key="dossier.name"
                    close
                    @click:close="handleChipDelete(index, 'dossier')"
                    >Dossier:{{ dossier.name }}</v-chip
                  >
                </template>
                <template v-for="(client, index) in editedItem.client">
                  <v-chip
                    class="ma-1"
                    :key="client"
                    close
                    @click:close="handleChipDelete(index, 'client')"
                    >Client:{{ client }}</v-chip
                  >
                </template>
                <template v-for="(operation, index) in editedItem.operation">
                  <v-chip
                    class="ma-1"
                    :key="operation.name"
                    close
                    @click:close="handleChipDelete(index, 'operation')"
                    >Operation:{{ operation.name }}</v-chip
                  >
                </template>
                <template v-for="(department, index) in editedItem.department">
                  <v-chip
                    class="ma-1"
                    :key="department"
                    close
                    @click:close="handleChipDelete(index, 'department')"
                    >Departement:{{ department }}</v-chip
                  >
                </template>

                <template v-if="editedItem.exact_date">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleDateDelete('exact_date')"
                    >Date:{{ editedItem.exact_date }}</v-chip
                  >
                </template>
                <template v-if="editedItem.start_date && editedItem.end_date">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleMultipleDateDelete"
                    >Entre le :{{ editedItem.start_date }} et le :
                    {{ editedItem.end_date }}</v-chip
                  >
                </template>
                <template v-if="editedItem.start_date && !editedItem.end_date">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleDateDelete('start_date')"
                    >Aprés le :{{ editedItem.start_date }}</v-chip
                  >
                </template>
                <template v-if="editedItem.end_date && !editedItem.start_date">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleDateDelete('end_date')"
                    >Avant le :{{ editedItem.end_date }}</v-chip
                  >
                </template>
              </div>
              <div>
                <v-chip class="chip-delete">
                  <v-icon @click="filterDialog = true" outlined>
                    mdi-filter
                  </v-icon>
                </v-chip>
              </div>
            </div>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.timesheet.start_date="{ item }">
        {{ item.timesheet.start_date | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.timesheet.agent="{ item }">
        <Avatar :item="item.timesheet.agent" />
        <span class="ml-2">{{ item.timesheet.agent.name }}</span>
      </template>
      <template v-slot:item.duration="{ item }">
        {{ item.duration | moment_time }}
      </template>
    </v-data-table>
    <v-dialog v-model="lineDetail" max-width="418px">
      <v-card>
        <v-card-title class="justify-space-between">
          <span class="headline">Détails Ligne time sheet</span>
          <v-btn color="black" dark icon right @click="lineDetail = false">
            <v-icon color="#B2BBAB">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pa-0" cols="12" md="12" sm="6">
                <strong>Détails d'opération :</strong>
                <span v-if="timesheetLine.timesheet">{{
                  timesheetLine.details
                }}</span>
                <span v-else>Cette tâche ne contient pas de détails</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  Avatar,
  Button,
  AutoComplete,
  TextInput,
  CustomDatePicker,
} from "@/components/base";
import {
  getAllTimeSheetByAdmin,
  getAllTimeSheetExported,
  getYears,
} from "@/services/timesheet.api";
import { getActiveUsers } from "@/services/users.api";
import { getDossiersList } from "@/services/dossiers.api";
import { getAllOperations } from "@/services/operations.api";
import departments from "@/assets/data/departments.json";
import { getClientsList } from "@/services/clients.api";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";

export default {
  components: { Button, Avatar, AutoComplete, TextInput, CustomDatePicker },
  mixins: [infinityScrollMixin],
  data: () => ({
    loading: false,
    loadingExport: false,
    total: 0,
    options: {},
    disabledExactDate: false,
    showMultipleDates: false,
    checkDate: false,
    modal: false,
    operations: [],
    clients: [],
    dossiers: [],
    agents: [],
    lineDetail: false,
    filterDialog: false,
    totalHours: 0,
    timesheetLine: [
      {
        timesheet: {
          agent: {
            name: null,
          },
        },
      },
    ],
    items: [],
    departments: departments,
    dialog: false,
    headers: [
      {
        text: "Agent",
        align: "start",
        value: "timesheet.agent",
      },
      { text: "Date", value: "timesheet.start_date" },
      { text: "Dossier", value: "dossier.name" },
      { text: "Type d'opération", value: "operation.name" },
      { text: "Code d'opération", value: "title" },
      { text: "Département", value: "timesheet.agent.department" },
      { text: "Durée", value: "duration" },
      //{ text: "Actions", value: "actions", sortable: false }
    ],
    timesheets_lines: [],
    editedIndex: -1,
    editedItem: {
       year: [new Date().getFullYear()],
    },
    defaultItem: {},
    pageNumber: 1,
    last_page: 0,
    years: [],
    year: "",
    estimatedHours: 0,
  }),

  computed: {
    hours() {
      return "" + this.totalHours;
    },
    estimated() {
      return "" + this.estimatedHours;
    },
  },

  watch: {
    options: {
      handler() {
        this.editedItem.order_by = this.options.sortBy[0];
        this.editedItem.order_by_direction = this.options.sortDesc[0];
        this.timesheets_lines = [];
        this.pageNumber = 1;
        getAllTimeSheetByAdmin(this.pageNumber, this.editedItem).then(
          (response) => {
            this.timesheets_lines = response.data.lines.data;
            this.last_page = response.data.lines.last_page;
            this.totalHours = response.data.count;
            this.total = response.data.total;
            this.estimatedHours = response.data.estimatedHours;
          }
        );
      },
      deep: true,
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1;
        getAllTimeSheetByAdmin(this.pageNumber, this.editedItem).then(
          (response) => {
            this.timesheets_lines = [
              ...this.timesheets_lines,
              ...response.data.lines.data,
            ];
          }
        );
      }
    },
    dialog(val) {
      val || this.close();
    },
    "editedItem.year": {
      handler: function(newYear, oldYear) {
        if (JSON.stringify(newYear) !== JSON.stringify(oldYear)) {
          this.year = newYear;
          getAllTimeSheetByAdmin(this.pageNumber, this.editedItem).then(
            (response) => {
              this.timesheets_lines = response.data.lines.data;
              this.last_page = response.data.lines.last_page;
              this.totalHours = response.data.count;
              this.estimatedHours = response.data.estimatedHours;
            }
          );
        }
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    handleMultipleDateDelete() {
      this.editedItem["start_date"] = "";
      this.editedItem["end_date"] = "";
      this.save();
    },
    handleMultipleDate(status) {
      if (status === true) {
        this.editedItem["exact_date"] = "";
      } else {
        this.editedItem["start_date"] = "";
        this.editedItem["end_date"] = "";
      }
      this.showMultipleDates = !this.showMultipleDates;
      this.disabledExactDate = !this.disabledExactDate;
    },
    handleReset() {
      this.editedItem = {};
      this.save();
    },
    exportData() {
      this.loadingExport = true;
      getAllTimeSheetExported(this.editedItem).then((response) => {
        this.loadingExport = false;
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Piximind-interne.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    getClients() {
      getClientsList().then((response) => {
        this.clients = response.data;
      });
    },
    handleChipDelete(index, key) {
      this.editedItem[key].splice(index, 1);
      this.save();
    },
    handleDateDelete(key) {
      this.editedItem[key] = "";
      this.save();
    },
    handleTableClick(row) {
      this.lineDetail = true;
      this.timesheetLine = row;
    },
    initialize() {
      this.getClients();
      getDossiersList().then((response) => {
        this.dossiers = response.data;
      });
      getActiveUsers().then((response) => {
        this.agents = response.data.users;
      });
      getAllOperations().then((response) => {
        this.operations = response.data;
      });
      getAllTimeSheetByAdmin(this.pageNumber, this.editedItem).then(
        (response) => {
          this.timesheets_lines = response.data.lines.data;
          this.last_page = response.data.lines.last_page;
          this.totalHours = response.data.count;
          this.estimatedHours = response.data.estimatedHours;
        }
      );
      getYears().then((response) => {
        this.years = response.data.years;
      });
    },
    close() {
      this.filterDialog = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign(
          { year: []},
          this.defaultItem
        );
        this.editedIndex = -1;
      });
    },
    save() {
      this.loading = true;
      this.pageNumber = 1;
      getAllTimeSheetByAdmin(this.pageNumber, this.editedItem).then(
        (response) => {
          this.loading = false;
          this.timesheets_lines = response.data.lines.data;
          this.last_page = response.data.lines.last_page;
          this.totalHours = response.data.count;
          this.estimatedHours = response.data.estimatedHours;
          this.dialog = false;
          this.filterDialog = false;
        }
      );
    },
  },
};
</script>
<style scoped>
.select-multiple-date {
  margin-top: -5px !important;
}

::v-deep .v-text-field__details {
  display: none;
}

.year-input {
  ::v-deep .v-select__slot {
    width: 100px;
  }
}
</style>
