import httpClient from "./httpClient";

const END_POINT = "/api/clients";
const getClientsList = department => httpClient.get(END_POINT + "/all?department=" + department);
const getAllClients = page => httpClient.get(END_POINT + '?page=' + page);

const addClient = client => httpClient.post(END_POINT, client);
const updateClient = id => httpClient.put(END_POINT, id);
const searchClient = name => httpClient.get('/api/search/clients/' + name);

export { getAllClients, getClientsList, addClient, updateClient, searchClient };
